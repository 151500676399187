<template>
    <div class="salehouse">
        <div class="container">
            <p class="title2 title-border">{{ $t('serviceSaleHouse.service')}}</p>
            <div class="detail-salehouse">
                <p>{{ $t('serviceSaleHouse.detail1')}} <br> {{ $t('serviceSaleHouse.detail2')}} <br> {{ $t('serviceSaleHouse.detail3')}} <br> {{ $t('serviceSaleHouse.detail4')}} <br> {{ $t('serviceSaleHouse.detail5')}}</p>
                <p class="pricerate-salehouse">{{ $t('serviceSaleHouse.pricerate')}}</p>
                <div class="detail-pSale">
                    <p>{{ $t('serviceSaleHouse.rate1')}} <br> {{ $t('serviceSaleHouse.rate2')}}</p>
                </div>
                <p class="title-send-info">{{ $t('serviceSaleHouse.send')}}</p>
                <p style="margin-bottom: 8%;">📞 <a href="tel:0834249090" class="router-home">083 - 424 - 9090</a> คุณตี๋ ณัฐวัฒน์ <br> <a href="http://line.me/ti/p/~Silpaphat" class="router-home">Line ID : silpaphat</a> <br> ✉ : <a href="mailto:info@assetup.co.th" class="router-home">info@assetup.co.th</a></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>